@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: "Monoton";
  src: url("./fonts/Monoton-Regular.ttf");
}

@font-face {
  font-family: 'Pixeled';
  src: url('./fonts/Pixeled.woff2') format('woff2');
}

body {
  background: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
